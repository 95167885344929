<template>
    <div class="d-flex container h-100 justify-content-center">
        <div class="content-access align-self-center text-center">
            <figure class="mb-5">
                <img
                    src="~@/assets/images/logo-beaver.png"
                    alt="Beaver"
                    width="155px"
                />
            </figure>

            <div class="card text-left p-4 shadow">
                <h2 class="d-block text-center p-3">Acesso ao sistema</h2>
                <form class="card-body" @submit="handlerSubmit">
                    <div class="form-group">
                        <!-- <label for="username">E-mail</label> -->
                        <input
                            type="text"
                            class="form-control"
                            id="username"
                            v-model="user.username"
                            :class="{
                                'border-danger': isEmptyUsername
                            }"
                            placeholder="inserir usuário"
                            autocomplete="off"
                        />
                        <div
                            class="error text-danger empty-username"
                            v-if="isEmptyUsername"
                        >
                            Este campo é de preenchimento obrigatório.
                        </div>
                    </div>
                    <div class="form-group">
                        <!-- <label for="password">Senha:</label> -->
                        <input
                            type="password"
                            class="form-control"
                            id="password"
                            v-model="user.password"
                            :class="{
                                'border-danger': isEmptyPassword
                            }"
                            placeholder="inserir password"
                        />
                        <div
                            class="error text-danger empty-password"
                            v-if="isEmptyPassword"
                        >
                            Este campo é de preenchimento obrigatório.
                        </div>
                    </div>

                    <div class="alert alert-danger" v-if="getAuthError">
                        Erro ao fazer o login.
                        <button
                            type="button"
                            class="close"
                            data-dismiss="alert"
                            aria-label="Close"
                            @click="closeError"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <button
                        id="handlerSubmit"
                        type="submit"
                        class="btn btn-primary btn-access mt-2"
                        v-if="!isLoading"
                    >
                        Entrar
                    </button>

                    <div
                        class="w-100 h-100 d-flex flex-column justify-content-center align-items-center"
                        v-else
                    >
                        <div class="spinner-border text-primary" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import { LoginMixin } from './login-mixin'

export default {
    name: 'LoginView',
    mixins: [LoginMixin]
}
</script>
<style src="./login.less" lang="less" scoped />
