import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

export const LoginMixin = {
    data: () => ({
        user: {
            username: '',
            password: ''
        },
        isLoading: false
    }),
    validations: {
        user: {
            username: {
                required
            },
            password: {
                required
            }
        }
    },
    watch: {
        getAuthError: function () {
            if (this.getAuthError) {
                this.isLoading = !this.getAuthError
            }
        }
    },
    computed: {
        ...mapGetters(['getAuthError']),
        isEmptyUsername() {
            return (
                this.$v.user.username.$dirty && !this.$v.user.username.required
            )
        },
        isEmptyPassword() {
            return (
                this.$v.user.password.$dirty && !this.$v.user.password.required
            )
        }
    },
    methods: {
        handlerSubmit(e) {
            e.preventDefault()
            if (this.$v.$invalid) {
                this.$v.$touch()
            } else {
                this.isLoading = true
                this.$store.dispatch('login', this.user)
            }
        },
        closeError() {
            this.$store.dispatch('resetLoginError')
        }
    }
}
